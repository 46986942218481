export function cartesian<T = any>(lists: T[][]): T[][] {
  return cartesionInner(lists).map((a: any) => (Array.isArray(a) ? a : [a]));
}

function cartesionInner<T = any>(lists: T[][]): T[][] | T[] {
  if (lists.length > 1) {
    return lists[0].flatMap((v) => cartesian(lists.slice(1)).map((c: any) => [v].concat(c)));
  }
  return lists[0];
}

/**
 * Takes an integer value and returns the item at that index,
 * allowing for positive and negative integers.
 * Negative integers count back from the last item in the array.
 *
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/at
 * https://github.com/tc39/proposal-relative-indexing-method#polyfill
 */
export function atIndex<T = any>(array: Array<T>, n: number): T | undefined {
  // ToInteger() abstract op
  n = Math.trunc(n) || 0;
  // Allow negative indexing from the end
  if (n < 0) n += array.length;
  // OOB access is guaranteed to return undefined
  if (n < 0 || n >= array.length) return undefined;
  // Otherwise, this is just normal property access
  return array[n];
}

// https://stackoverflow.com/questions/2218999/how-to-remove-all-duplicates-from-an-array-of-objects
export function noDuplicates<T = any>(arr: Array<T>) {
  return arr.filter((v, i, a) => a.findIndex((t) => JSON.stringify(t) === JSON.stringify(v)) === i);
}

export function mapDefiniteArray<T>(source: Array<T | undefined | null>): Array<T> {
  return source as Array<T>;
}

export function groupBy<T>(array: T[], predicate: (value: T, index: number, array: T[]) => string) {
  return array.reduce((acc, value, index, array) => {
    (acc[predicate(value, index, array)] ||= []).push(value);
    return acc;
  }, {} as { [key: string]: T[] });
}

export function insertIntoArray<T>(arr: T[], index: number, ...newItems: any): Array<T> {
  return [...arr.slice(0, index), ...newItems, ...arr.slice(index)];
}

/** Creates an array and fills it with values. */
export function createRange<T>(length: number, valueFunction: (index: number) => T): T[] {
  const valuesArray = Array(length);
  for (let i = 0; i < length; i++) {
    valuesArray[i] = valueFunction(i);
  }
  return valuesArray;
}
